<template>
  <v-app>
    <v-content class="white">
      <section id="hero">
        <v-img
          :src="require('@/assets/Home/header.jpg')"
          class="white--text"
          gradient="rgba(5, 11, 31, .1), rgba(5, 11, 31, .3)"
          :height="minHeight"
          max-height="690px"
        >
          <v-container class="fill-height px-4 py-0">
            <v-responsive
              height="100%"
              width="100%"
            >
              <v-row justify="center">            
                  <v-card :height="logoSize" :width="logoSize" elevation="6" class="mx-0 white" :style="styleLogo">
                    <v-img
                      :width="logoSize"
                      :height="logoSize"
                      :aspect-ratio="1"
                      contain
                      :src="require('@/assets/Todo-carta-logo.png')"
                      class="mt-2"
                    ></v-img>           
                  </v-card>
              </v-row>
              <v-row justify="center" align="end" class="mt-4">
                <v-col cols="12" class="text-center d-inline-flex justify-center py-0">
                  <div class="slogan-landing white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}" style="margin-top: 4px;">
                    UNA MARCA DE
                  </div>
                    <img :src="require('@/assets/Home/AudituxLogo.svg')" alt="" :width="audituxSize.width" :height="audituxSize.height">
                  <div class="slogan-landing white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}" style="margin-top: 3px; padding-left: 4px;">
                   INFORMÁTICA</div>
                </v-col>
              </v-row>
              </div>
            </v-responsive>
          </v-container>
        </v-img>
      </section>
      <v-container fluid style="height: 70vh;">
      	<v-row align="center" justify="center" no-gutters class="pt-6 pt-sm-6 pt-md-12 pt-lg-12">
      		<v-col cols="12" sm="10" md="8" lg="8" class="pt-8 px-4 text-center">
      			<span class="title-error-landing" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">Ha ocurrido un Error (404).</span>         		
          </v-col>
          <v-col cols="12" sm="10" md="8" lg="8" class="pt-6 px-4 text-center">
            <span class="slogan-landing" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">No hemos encontrado la página que estás buscando.</span>            
          </v-col>
      	</v-row>
        <v-row align="center" no-gutters>
          <v-col cols="12" sm="10" md="8" lg="8" offset-md="2" class="pt-8 mt-8 text-center btn-landing">
            <v-btn color="orange lighten-1" class="btn-landing" height="58.93px" x-large depressed dark to="/">ir a Principal</v-btn>
          </v-col>
        </v-row>
      </v-container> 
      <footerLanding />     
    </v-content>
  </v-app>
</template>
<script>
	export default{
		name: 'LandingError',
    components: { 
      footerLanding: () => import('@/views/landing/Home/Footer'), 
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '40vh' : '30vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      logoSize () {
        const size = this.$vuetify.breakpoint.md ? '8' : this.$vuetify.breakpoint.lg ? '10' : this.$vuetify.breakpoint.xl ? '20' : '5'

        return `${size}rem`
      },
      styleLogo(){
        return {
           'margin-top': this.$vuetify.breakpoint.mdAndUp ? '-30px !important' : '-15px !important'
        }
      },
      audituxSize(){
        return {
          width: this.$vuetify.breakpoint.xl ? '182' : this.$vuetify.breakpoint.mdAndUp ? '112' :  '86',
          height: this.$vuetify.breakpoint.xl ?  '35' : this.$vuetify.breakpoint.mdAndUp ? '28' : '18',
        }
      }
    },
	}
</script>
<style lang="sass">
  .title-landing
    font-weight: 100
    line-height: 1.15em
    font-family: Roboto, sans-serif !important
    &.lg-view 
      letter-spacing: 0.4em !important
      font-size: 3.3125rem !important
    &.sm-view 
      letter-spacing: 0.15em !important
      font-size: 2rem !important
    &.xl-view 
      letter-spacing: 0.6em !important
      font-size: 4.0125rem !important

  .slogan-landing
    line-height: 1.4em
    font-weight: 100
    font-family: Roboto, sans-serif !important
    color: #2c2a2b !important
    &.lg-view 
      font-size: 1.125rem !important
      letter-spacing: 0.2em !important
    &.sm-view 
      font-size: 0.825rem !important
      letter-spacing: 0.1em !important
    &.xl-view 
      font-size: 1.6rem !important
      letter-spacing: 0.2em !important

  .title-error-landing
    font-weight: 700
    line-height: 1.15em
    font-family: Museo-700 !important
    margin: 40px 0
    color: #2c2a2b !important
    &.lg-view 
      letter-spacing: 0.1em !important
      font-size: 2.8125rem !important
    &.sm-view 
      letter-spacing: 0.15em !important
      font-size: 2rem !important
    &.xl-view 
      letter-spacing: 0.1em !important
      font-size: 3.1125rem !important
  .btn-landing
    font-family: Montserrat, sans-serif !important
    font-weight: 600
</style>